<template>
  <div class="project-floor-plan-detail__body">
    <div class="row p-2">
      <h4 class="mb-2">Floor Plan Images</h4>
      <cool-light-box
        :items="floorPlanImages"
        :index="floorPlanImageIndex"
        @close="floorPlanImageIndex = null"
        :slideshow="false"
      >
      </cool-light-box>
      <swiper class="swiper col-12 d-flex" :options="swiperOption">
        <swiper-slide
          v-for="(floorPlan, imageIndex) in floorPlanImages"
          :key="imageIndex"
          class="xl-col-2 lg-col-3 md-col-4 sm-col-4 col-6"
        >
          <div class="image-container">
            <div
              class="image cursor-pointer"
              @click="floorPlanImageIndex = imageIndex"
            >
              <img :src="floorPlan" @error="$setAltPhoto" loading="lazy" />
            </div>
          </div>
        </swiper-slide>
        <div class="swiper-button-prev" slot="button-prev"></div>
        <div class="swiper-button-next" slot="button-next"></div>
      </swiper>
    </div>

    <div v-if="unitImages.length > 0" class="card p-2 row mb-1">
      <h4 class="mb-2">Unit Images</h4>
      <cool-light-box
        :items="unitImages"
        :index="unitImageIndex"
        @close="unitImageIndex = null"
        :slideshow="false"
      >
      </cool-light-box>
      <swiper class="swiper col-12 d-flex" :options="swiperOption">
        <swiper-slide
          v-for="(unit, imageIndex) in unitImages"
          :key="imageIndex"
          class="xl-col-2 lg-col-3 md-col-4 sm-col-4 col-6"
        >
          <div class="image-container">
            <div
              class="image cursor-pointer"
              @click="unitImageIndex = imageIndex"
            >
              <img :src="unit" @error="$setAltPhoto" loading="lazy" />
            </div>
          </div>
        </swiper-slide>
        <div class="swiper-button-prev" slot="button-prev"></div>
        <div class="swiper-button-next" slot="button-next"></div>
      </swiper>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  mixins: [],
  props: {
    floorPlan: {
      type: Object,
      required: true
    }
  },
  data: function () {
    return {
      floorPlanImageIndex: null,
      unitImageIndex: null,

      // swiper
      swiperOption: {
        slidesPerView: "auto",
        spaceBetween: 5,
        freeMode: true,
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev"
        }
      }
    };
  },
  computed: {
    floorPlanImages() {
      let floorPlanImages = this.floorPlan.image.map((item) => {
        return this.$getFileURL(item);
      });
      return [...floorPlanImages];
    },
    unitImages() {
      let unitImages = [];
      if (this.floorPlan.unitImage.length > 0) {
        unitImages = this.floorPlan.unitImage.map((item) => {
          return this.$getFileURL(item);
        });
      }
      return [...unitImages];
    }
  },
  watch: {},
  created: function () {},
  beforeDestroy: function () {},
  mounted: function () {},
  methods: {}
};
</script>

<style lang="scss">
.project-floor-plan-detail__body {
  .image-container {
    @include flex(row, center, center);
    border: 1px solid #ddd;
    position: relative;

    &:before {
      content: "";
      display: block;
      padding-top: 100%;
    }

    .image {
      display: block;
      width: 100%;
      img {
        @include image(cover);
        position: absolute;
        bottom: 0;
        left: 0;
        top: 0;
      }
    }
  }

  .swiper-button-next,
  .swiper-button-prev {
    opacity: 0.6;
    color: white;
  }
}
</style>
